#D3Cube {
    width: 45px;
    height: 45px;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

}

#D3Cube>div {
    position: absolute;
    width: 45px;
    height: 45px;
    align-items: center;


}

#side1 {
    -webkit-transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ(23px);
    background: linear-gradient(90deg, rgba(4, 0, 132, 0.171) 0%, rgba(127,0,132,0.06317113026851362) 100%);
}

#side2 {
    -webkit-transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(23px);
    background: linear-gradient(90deg, rgba(4, 0, 132, 0.171) 0%, rgba(127,0,132,0.06317113026851362) 100%);

}

#side3 {
    -webkit-transform: translateX(0px) translateY(0px) translateZ(23px);
    background: linear-gradient(90deg, rgba(4, 0, 132, 0.171) 0%, rgba(127,0,132,0.06317113026851362) 100%);

}

#side4 {
    -webkit-transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(23px);
    background: linear-gradient(90deg, rgba(4, 0, 132, 0.171) 0%, rgba(127,0,132,0.06317113026851362) 100%);
    border: 1px solid linear-gradient(0deg, rgba(255,255,255,0.28494623655913975) 0%, rgba(129,0,136,0.7018265924533876) 100%);

}

#side5 {
    -webkit-transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(23px);
    background: linear-gradient(90deg, rgba(4, 0, 132, 0.171) 0%, rgba(127,0,132,0.06317113026851362) 100%);

}

#side6 {
    -webkit-transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(23px);
    background: linear-gradient(90deg, rgba(4, 0, 132, 0.171) 0%, rgba(127,0,132,0.06317113026851362) 100%);

}

#D3Cube {
    -webkit-animation: cubeRotation 20s ease-in-out infinite;
    animation: cubeRotation 25s ease-in-out infinite;
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;

}


@keyframes cubeRotation {
    from {
    transform: rotateX(0deg) rotateY(0deg);
    -ms-transform: rotateX(0deg) rotateY(0deg);
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    }
   to {
    transform: rotateX(360deg) rotateY(360deg);
    -ms-transform: rotateX(360deg) rotateY(360deg);
    -webkit-transform: rotateX(360deg) rotateY(360deg);
    }
   }
   @-webkit-keyframes rotate {
    from {
    transform: rotateX(0deg) rotateY(0deg);
    -ms-transform: rotateX(0deg) rotateY(0deg);
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    }
   to {
    transform: rotateX(360deg) rotateY(360deg);
    -ms-transform: rotateX(360deg) rotateY(360deg);
    -webkit-transform: rotateX(360deg) rotateY(360deg);
    }
   }