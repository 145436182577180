.containerContact {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.buttonmenu {
  color: white;
  height: 55px;
  width: 150px;
}




.colorButton1:hover {

  box-shadow: 2px 2px 9px black;
  border-bottom: 2px solid #00d4ff;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.54419134396355353) 0%, rgba(0, 1, 87, 0.478132118451025) 100%);
  color: #00d4ff;
}

.colorButton2:hover {
  box-shadow: 2px 2px 9px black;
  border-bottom: 2px solid  #a900ff;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.54419134396355353) 0%, rgba(0, 1, 87, 0.478132118451025) 100%);
  color: #a900ff;
}

.colorButton3:hover {
  box-shadow: 2px 2px 9px black;
  border-bottom: 2px solid  #6d00ff;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.54419134396355353) 0%, rgba(0, 1, 87, 0.478132118451025) 100%);
  color: #6d00ff;
}

.colorButton4:hover {
  box-shadow: 2px 2px 9px black;
  border-bottom: 2px solid  linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(109,0,255,1) 100%);
  background: radial-gradient(circle, rgba(0, 0, 0, 0.54419134396355353) 0%, rgba(0, 1, 87, 0.478132118451025) 100%);
  color: #6d00ff;
}