.presentation {
  margin-top: 90px;
  padding: 0;
  width: 80%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.containerd {
  position: relative;
  width: 40%;
  height: 50%;
  margin-top: 230px;
  background: rgba(0, 0, 0, 0);
  transform: rotate(-30deg) skew(25deg) scale(0.8);
  transition: 0.5s;
}

.containerd img {
  position: absolute;
  width: 100%;
  transition: 4s;
}

.containerd.active img:nth-child(5) {
  transform: translate(200px, -200px);
  opacity: 1;
}

.containerd.active img:nth-child(4) {
  transform: translate(160px, -160px);
  opacity: 0.8;
}

.containerd.active img:nth-child(3) {
  transform: translate(120px, -120px);
  opacity: 0.6;
}

.containerd.active img:nth-child(2) {
  transform: translate(80px, -80px);
  opacity: 0.4;
}

.containerd.active img:nth-child(1) {
  transform: translate(40px, -40px);
  opacity: 0.2;
}




.textphone {
  text-align: left;
  margin-top: 35%;
  margin-bottom: 30%;
  color: black;
  font-size: 20px;
  font-size: bold;
}

.styletext {
  font-family: 'Inter-Thin'

}

.title {
  font-size: 50px;
}

.textcard {
  text-align: left;
}

.text3d {
  right: 0;
  transform: translate(50%,-50%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 6em;
  font-weight: 700;
  color: #ca1616;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
}


@media (max-width: 767px) {
  .containerd {
    width: 60%;
    height: 65%;
    margin-right: 45px;
  }

  .containerd.active img:nth-child(5) {
    transform: translate(100px, -100px);
    opacity: 1;
  }

  .containerd.active img:nth-child(4) {
    transform: translate(80px, -80px);
    opacity: 0.8;
  }

  .containerd.active img:nth-child(3) {
    transform: translate(60px, -60px);
    opacity: 0.6;
  }

  .containerd.active img:nth-child(2) {
    transform: translate(40px, -40px);
    opacity: 0.4;
  }

  .containerd.active img:nth-child(1) {
    transform: translate(20px, -20px);
    opacity: 0.2;
  }

  .presentation {
    margin-top: 0px;
    height: 300px;
    width: 70%;


  }

  .cards {
    max-width: 100%;
  }

  .textphone {
    text-align: center;
    margin-top: 15%;
  }

  .title {
    font-size: 25px;
  }

  .textphone {
    text-align: center;
    margin-top: 35%;
    margin-bottom: 30%;
    color: black;
    font-size: 20px;
  }
}