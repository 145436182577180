.card {
  width: 200%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 2500px;
  margin-top: 55%;
  left: 5%;

}

.margincard {
  margin-top: 250px;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper {
  transition: all 3s;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.card.activecard .wrapper {
  transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
  box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
}

.wrapper::before,
.wrapper::after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 80px;
  transition: all 3s;
  position: absolute;
  left: 0;
}

.wrapper::before {
  top: 0;
  height: 100%;
  background-image: linear-gradient(to top,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%);
}

.wrapper::after {
  bottom: 0;
  opacity: 1;
  background-image: linear-gradient(to bottom,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%);
}

.card.activecard .wrapper::before,
.wrapper::after {
  opacity: 1;
}

.card.activecard .wrapper::after {
  height: 120px;
}

.title {
  width: 100%;
  margin-top: 140%;
  transition: transform 3s;
}

.card.activecard .title {
  transform: translate3d(0%, -50px, 100px);
}

.character {
  width: 100%;
  opacity: 0;
  transition: all 3s;
  position: absolute;
  z-index: -1;
  margin-bottom: 10%;
}

.card.activecard .character {
  opacity: 1;
  transform: translate3d(0%, -30%, 100px);
}

@media (max-width: 767px) {
  .card {
    margin-top: 120%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 2500px;
    left: 0%;

  }

  .margincard {
    margin-top: 40%;
  }

  .wrapper {
    width: 110%;
  }


}