.colorsection1{
    background:linear-gradient(90deg, rgba(111,156,235,1) 42%,  rgba(72,116,194,1) 100%) ;
}


@media (max-width: 767px) {

.titlesection{
font-size: x-large;}

}