

.container {
  position: relative;
  text-align: center;
  color: white;
  
}


.centered {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo{
  width:90%;

}

.centeredPhoto {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:15%;

}

@media (max-width: 767px) {
  .centered {
    position: absolute;
    top: 90%;
    left: 35%;
  }

  .logo{
    margin-left: 10%;
    width:120%;
    margin-bottom: 10%;

  }
  .centeredPhoto {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:30%;

  }
  
}